<template src="./profile.html"></template>
<style scoped src="./profile.css"></style>
<script>
import Loading from 'vue-loading-overlay'
import accountConstants from '@/constants/AccountConstants.vue'
export default {
    data () {
        return {
            userinfo: {},
            loaderVisible : 'false'
        }
    },
    components : {
        Loading
    },
    methods : {
        goToMain() {
            this.$router.push({
                name : 'Dashboard'
            })
        },
        printProfile() {
            this.$htmlToPaper('printMe')
        }
    },
    beforeMount () {
        this.loaderVisible = true
        this.userinfo = JSON.parse(localStorage.getItem('user-info'))
        Object.keys(this.userinfo).map(key =>{
            this.userinfo[key] = this.userinfo[key]._text ? this.userinfo[key]._text : ''
            if(key === 'AccountStatus') 
                this.userinfo[key] = accountConstants.accountStatus.find(item => item.Value === this.userinfo[key])?.Text || null
            if(key === 'UserIdType')
                this.userinfo[key] = accountConstants.userIdType.find(item => item.Value === this.userinfo[key])?.Text || null
        })
    },
    mounted () {
        try {
            (document.querySelectorAll('.form-control')).forEach(field=> {
            if(!(field.innerHTML && field.innerHTML.length > 0 )) 
                field.closest('.row').style.display = 'none'
            })
            this.loaderVisible = false
        } catch (error) {
            this.loaderVisible = false
        }
    }
}
</script>
